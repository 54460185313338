/**
 * MageWorx Ultimate Sales Boost compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable no-magic-numbers */

/** @namespace Scandiweb/MageworxUltimateSalesBoost/Util/Timer/Helper/isObject */
export const isObject = (value) => value && typeof value === 'object' && !Array.isArray(value);

/** @namespace Scandiweb/MageworxUltimateSalesBoost/Util/Timer/Helper/sleep */
export const sleep = (seconds) => new Promise((resolve) => setTimeout(resolve, seconds * 1000));

/** @namespace Scandiweb/MageworxUltimateSalesBoost/Util/Timer/Helper/deepMerge */
export const deepMerge = (objectA = {}, objectB = {}) => {
    const keys = new Set(Object.keys(objectA).concat(Object.keys(objectB)));
    return Array.from(keys).reduce((acc, key) => {
        const isKeyInObjectA = key in objectA;
        const objectAvalue = objectA[key];
        const objectBvalue = objectB[key];

        if (isKeyInObjectA && key in objectB) {
            acc[key] = isObject(objectAvalue) && isObject(objectBvalue)
                ? deepMerge(objectAvalue, objectBvalue)
                : objectBvalue;

            return acc;
        }

        acc[key] = isKeyInObjectA ? objectAvalue : objectBvalue;

        return acc;
    }, {});
};

/** @namespace Scandiweb/MageworxUltimateSalesBoost/Util/Timer/Helper/pad */
export const pad = (string) => `00${string}`.slice(-2).split('').map(Number);

/** @namespace Scandiweb/MageworxUltimateSalesBoost/Util/Timer/Helper/createElement */
export const createElement = (tagName, classList, innerHtml) => {
    try {
        const element = document.createElement(tagName);

        if (classList && Array.isArray(classList)) {
            classList.forEach((className) => {
                element.classList.add(className);
            });
        }

        if (classList) {
            element.classList.add(classList);
        }

        if (innerHtml?.length) {
            element.innerHTML = innerHtml;
        }

        return element;
    } catch {
        throw new Error(`'${tagName}' is not valid HTML element.`);
    }
};

/** @namespace Scandiweb/MageworxUltimateSalesBoost/Util/Timer/Helper/transformPairs */
export const transformPairs = (array) => array.reduce((acc, value, i, all) => {
    if (i % 2 === 0) {
        acc.push(`${value}${all[i + 1]}`);
    }

    return acc;
}, []);
