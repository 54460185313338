/**
 * MageWorx Ultimate Sales Boost compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { pad } from './helper';

/* eslint-disable no-magic-numbers */

/** @namespace Scandiweb/MageworxUltimateSalesBoost/Util/Timer/Time/getDate */
export const getDate = (timestamp) => {
    if (!timestamp) {
        return new Date();
    }

    return timestamp instanceof Date ? timestamp : new Date(timestamp);
};

/** @namespace Scandiweb/MageworxUltimateSalesBoost/Util/Timer/Time/getDiff */
export const getDiff = (date) => {
    const diff = +date - new Date().getTime();

    return diff > 0 ? diff : 0;
};

/** @namespace Scandiweb/MageworxUltimateSalesBoost/Util/Timer/Time/getDays */
export const getDays = (diff) => {
    const days = Math.floor(diff / 864e5);

    return days > 99 ? 99 : days;
};

/** @namespace Scandiweb/MageworxUltimateSalesBoost/Util/Timer/Time/getHours */
export const getHours = (diff) => Math.floor((diff / 36e5) % 24);

/** @namespace Scandiweb/MageworxUltimateSalesBoost/Util/Timer/Time/getMinutes */
export const getMinutes = (diff) => Math.floor((diff / 1e3 / 60) % 60);

/** @namespace Scandiweb/MageworxUltimateSalesBoost/Util/Timer/Time/getSeconds */
export const getSeconds = (diff) => Math.floor((diff / 1e3) % 60);

/** @namespace Scandiweb/MageworxUltimateSalesBoost/Util/Timer/Time/getTime */
export const getTime = (date) => {
    const diff = getDiff(date);

    return [
        ...pad(getDays(diff)),
        ...pad(getHours(diff)),
        ...pad(getMinutes(diff)),
        ...pad(getSeconds(diff))
    ];
};
