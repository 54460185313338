/**
 * MageWorx Ultimate Sales Boost compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable no-magic-numbers */

import { getDate } from './Time';
import { THEMES } from './types';

/** @namespace Scandiweb/MageworxUltimateSalesBoost/Util/Timer/theme_flip_double */
export const theme_flip_double = async () => {
    const theme = await import(
        /* webpackMode: "lazy", webpackChunkName: "mageworx-countdown-timer-theme-flip-double" */
        './themes/flip_double'
    );

    return theme;
};

/** @namespace Scandiweb/MageworxUltimateSalesBoost/Util/Timer/theme_flip_single */
export const theme_flip_single = async () => {
    const theme = await import(
        /* webpackMode: "lazy", webpackChunkName: "mageworx-countdown-timer-theme-flip-single" */
        './themes/flip_single'
    );

    return theme;
};

/** @namespace Scandiweb/MageworxUltimateSalesBoost/Util/Timer/theme_inline */
export const theme_inline = async () => {
    const theme = await import(
        /* webpackMode: "lazy", webpackChunkName: "mageworx-countdown-timer-theme-inline" */
        './themes/inline'
    );

    return theme;
};

/** @namespace Scandiweb/MageworxUltimateSalesBoost/Util/Timer/theme_plain */
export const theme_plain = async () => {
    const theme = await import(
        /* webpackMode: "lazy", webpackChunkName: "mageworx-countdown-timer-theme-plain" */
        './themes/plain'
    );

    return theme;
};

/** @namespace Scandiweb/MageworxUltimateSalesBoost/Util/Timer/theme_roll */
export const theme_roll = async () => {
    const theme = await import(
        /* webpackMode: "lazy", webpackChunkName: "mageworx-countdown-timer-theme-roll" */
        './themes/roll'
    );

    return theme;
};

/** @namespace Scandiweb/MageworxUltimateSalesBoost/Util/Timer/theme_tick */
export const theme_tick = async () => {
    const theme = await import(
        /* webpackMode: "lazy", webpackChunkName: "mageworx-countdown-timer-theme-tick" */
        './themes/tick'
    );

    return theme;
};

export const METADATA = {
    [THEMES.FLIP_DOUBLE]: {
        importTheme: theme_flip_double,
        firstDigits: [99, 23, 59, 59]
    },
    [THEMES.FLIP_SINGLE]: {
        importTheme: theme_flip_single,
        firstDigits: [9, 9, 2, 3, 5, 9, 5, 9]
    },
    [THEMES.INLINE]: {
        importTheme: theme_inline,
        firstDigits: []
    },
    [THEMES.PLAIN]: {
        importTheme: theme_plain,
        firstDigits: []
    },
    [THEMES.ROLL]: {
        importTheme: theme_roll,
        firstDigits: []
    },
    [THEMES.TICK]: {
        importTheme: theme_tick,
        firstDigits: []
    }
};

/** @namespace Scandiweb/MageworxUltimateSalesBoost/Util/Timer */
export class Timer {
    __construct(timestamp, containerElement, settings = {}) {
        if (!(containerElement instanceof Element)) {
            throw new Error('containerElement is not an HTML element');
        }

        this.date = getDate(timestamp);
        this.container = containerElement;
        this.settings = settings;
        this.valuesList = [];
        this.prevTime = [];
        this.indicators = [];
        this.timer = 0;
        this.isThemeInitialized = false;
        this.isThemeBuilt = false;
        this.timerStarted = false;
        this.timerFinished = false;
    }

    _start() {
        if (!this.isStarted()) {
            return;
        }

        this._updateTheme(
            this.date,
            this._getPrevTime.bind(this),
            this.valuesList,
            this.valuesListElements,
            this._setPrevTime.bind(this),
            this,
            this.indicators
        ).then(this._start.bind(this));
    }

    _setPrevTime(time = []) {
        this.prevTime = time;
    }

    _getPrevTime() {
        return this.prevTime;
    }

    async initTheme() {
        const { theme } = this.settings;

        if (!(theme in METADATA)) {
            throw new Error('Countdown theme not found');
        }
        const { importTheme, firstDigits } = METADATA[theme];

        const { build, update, getValueDigitsElements } = await importTheme();

        this.theme = theme;
        this.firstDigits = firstDigits;
        this._buildTheme = build;
        this._updateTheme = update;
        this._getValueDigitsElements = getValueDigitsElements;
        this.isThemeInitialized = true;
    }

    buildTheme() {
        if (!this.isThemeInitialized) {
            throw new Error('Countdown theme is not initialized. Run initTheme() first');
        }

        this._buildTheme(
            this.date,
            this.settings,
            this.valuesList,
            this.container,
            this.firstDigits,
            this.indicators
        );

        this.valuesListElements = this.valuesList.map(this._getValueDigitsElements);

        this.isThemeBuilt = true;

        return this;
    }

    start() {
        if (!this.isThemeInitialized) {
            throw new Error('Countdown theme is not initialized. Run initTheme() first');
        }

        if (!this.isThemeBuilt) {
            throw new Error('Countdown theme is not built. Run buildTheme() first');
        }

        this.timerStarted = true;

        requestAnimationFrame(this._start.bind(this));

        return this;
    }

    stop() {
        this.timerStarted = false;
        cancelAnimationFrame(this.timer);

        return this;
    }

    finish() {
        this.stop();
        this.timerFinished = true;

        return this;
    }

    isStarted() {
        return this.timerStarted;
    }

    isFinished() {
        return this.timerFinished;
    }
}

export default Timer;
