/**
 * MageWorx Ultimate Sales Boost compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable no-magic-numbers */

export const THEMES = {
    ROLL: 'roll',
    FLIP_SINGLE: 'flip_single',
    FLIP_DOUBLE: 'flip_double',
    INLINE: 'inline',
    PLAIN: 'plain',
    TICK: 'tick'
};

export const CLASSLIST = {
    CONTAINER: 'mw-cd',
    TITLE: 'mw-cd__title',
    SUFFIX: 'mw-cd__suffix',
    DIVIDER: 'mw-cd__divider',
    TIMER: 'mw-cd__timer',
    TIMER_SEGMENT: 'mw-cd__timer-segment',
    DIGIT_SEGMENT: 'mw-cd__timer-segment--digits',
    DIVIDER_SEGMENT: 'mw-cd__timer-segment--divider',
    DIGIT_WRAPPER: 'mw-cd__digit',
    DIGIT_SHADOW: 'mw-cd__digit-shadow',
    DIGIT_SHADOW_BACK: 'mw-cd__digit-shadow--back',
    DIGIT_SHADOW_TOP: 'mw-cd__digit-shadow--top',
    DIGIT_SHADOW_BOTTOM: 'mw-cd__digit-shadow--bottom',
    DIGIT_VALUE: 'mw-cd__digit-value',
    ACTIVE_DIGIT: 'mw-cd__digit--active',
    NEXT_DIGIT: 'mw-cd__digit-next',
    CURRENT_DIGIT: 'mw-cd__digit-current',
    TOP_DIGIT: 'mw-cd__digit-top',
    BOTTOM_DIGIT: 'mw-cd__digit-bottom',
    LABEL: 'mw-cd__label',
    TEXT: 'mw-cd__text',
    THEME_ACCENT: 'mw-cd--accent-',
    THEME_SIZE: 'mw-cd--size-',
    ROLL_CURRENT_VALUE: 'md-digit__value--current',
    ROLL_NEXT_VALUE: 'md-digit__value--next',
    INDICATORS_CONTAINER: 'mw-cd__indicators',
    INDICATOR: 'mw-cd__indicator',
    PLAIN_THEME: 'mw-cd--theme-plain',
    FLIP_SINGLE_THEME: 'mw-cd--theme-flip-single',
    FLIP_DOUBLE_THEME: 'mw-cd--theme-flip-double',
    SWITCH_THEME: 'mw-cd--theme-switch',
    TICK_THEME: 'mw-cd--theme-tick',
    ROLL_THEME: 'mw-cd--theme-roll',
    INLINE_THEME: 'mw-cd--theme-inline'
};
