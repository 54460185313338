/**
 * MageWorx Ultimate Sales Boost compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable no-magic-numbers */
/* eslint-disable no-new */
import Timer from './Timer';
/** @namespace Scandiweb/MageworxUltimateSalesBoost/Util/sleep */
export const sleep = (seconds) => new Promise((resolve) => setTimeout(resolve, seconds * 1000));

/** @namespace Scandiweb/MageworxUltimateSalesBoost/Util/isProduction */
export const isProduction = () => process.env.NODE_ENV === 'production';

/**
 * is value an integer or a string containing an integer
 * @namespace Scandiweb/MageworxUltimateSalesBoost/Util/isIntLike
 */
export const isIntLike = (value) => {
    if (Number.isInteger(value)) {
        return true;
    }

    if (typeof value === 'string') {
        return Number.isInteger(value * 1);
    }

    return false;
};

/** @namespace Scandiweb/MageworxUltimateSalesBoost/Util/isInViewport */
export const isInViewport = (element) => {
    const rect = element.getBoundingClientRect();

    return (
        rect.top >= 0
        && rect.left >= 0
        && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};

/** @namespace Scandiweb/MageworxUltimateSalesBoost/Util/isAllOptionsSelected */
export const isAllOptionsSelected = (allOptions, selectedOptions) => {
    const allOptionsKeys = Object.keys(allOptions);
    const selectedOptionsCount = allOptionsKeys.reduce(
        (acc, key) => (Object.prototype.hasOwnProperty.call(selectedOptions, key) ? acc + 1 : acc),
        0
    );

    return selectedOptionsCount === allOptionsKeys.length;
};

/** @namespace Scandiweb/MageworxUltimateSalesBoost/Util/getOptionsIds */
export const getOptionsIds = (configurableOptions, selectedOptions) => {
    const selectedOptionsKeys = Object.keys(selectedOptions);

    return selectedOptionsKeys.reduce((acc, key) => {
        const { attribute_id } = configurableOptions[key] || {};

        if (!attribute_id) {
            return acc;
        }

        return {
            ...acc,
            [attribute_id]: selectedOptions[key] // please keep (._. )
        };
    }, {});
};

/** @namespace Scandiweb/MageworxUltimateSalesBoost/Util/handleVisibilityChange */
export const handleVisibilityChange = (container, timer) => {
    if (
        document.visibilityState === 'visible'
        && isInViewport(container)
        && !timer.isStarted()
        && !timer.isFinished()
    ) {
        timer.start();
        return;
    }

    if (document.visibilityState === 'hidden' && timer.isStarted()) {
        timer.stop();
    }
};

/** @namespace Scandiweb/MageworxUltimateSalesBoost/Util/startCountdown */
export const startCountdown = async (
    campaign,
    containerRef,
    _isMobile,
    isSmall = false
) => {
    const {
        beforeTimerText, afterTimerText, theme, accent, timeStamp
    } = campaign;

    const container = containerRef.current;

    const timer = await new Timer(timeStamp * 1000, container, {
        theme,
        prefix: beforeTimerText,
        suffix: afterTimerText,
        labels: {
            days: 'days',
            hours: 'hrs',
            minutes: 'min',
            seconds: 'sec'
        },
        accent,
        size: isSmall ? 'small' : undefined
    });

    await timer.initTheme();

    timer.buildTheme();

    const intersectionObserver = new IntersectionObserver(
        (entries) => {
            const { isIntersecting } = entries[0] || {};

            if (isIntersecting && !timer.isStarted() && !timer.isFinished()) {
                timer.start();
                return;
            }

            if (!isIntersecting && timer.isStarted()) {
                timer.stop();
            }
        },
        {
            root: null,
            rootMargin: '0px',
            threshold: 0.5
        }
    );

    intersectionObserver.observe(container);

    window.addEventListener(
        'visibilitychange',
        handleVisibilityChange.bind(this, container, timer),
        { passive: true }
    );

    return {
        observer: intersectionObserver,
        handleVisibilityChange: handleVisibilityChange.bind(this, container, timer)
    };
};
